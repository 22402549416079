import { LOCALES } from 'lang/locales';

import { amountPrintMessages } from './amountPrint';
import { checkoutMessages } from './checkout';
import { chooseYourPrinterMessages } from './chooseYourPrinter';
import { commonMessages } from './common';
import { comparisonTableMessages } from './comparisonTable';
import { disclaimersMessages } from './disclaimers';
import { enviromentMessages } from './enviroment';
import { faqSectionMessages } from './faqSection';
import { heroMessages } from './heroScreen';
import { heroVariationAMessages } from './heroVariationA';
import { footerMessages } from './homeFooterBar';
import { howManyPagesMessages } from './howManyPages';
import { notFoundMessages } from './notFound';
import { orderYourPlanMessages } from './orderYourPlan';
import { paperQuantityMessages } from './paperQuantity';
import { purchaseCompleteMessages } from './purchaseComplete';
import { renewalNoticeMessages } from './renewalNotice';
import { simplePrintMessages } from './simplePrint';
import { stepsMessages } from './steps';
import { summaryMessages } from './summary';
import { sunsetMessages } from './sunset';
import { termsOfServiceMessages } from './terms';
import { upgradeLandingMessages } from './upgradeLanding';
import { upgradePrinterSelectionMessages } from './upgradePrinterSelection';
import { upgradePurchaseCompleteMessages } from './upgradePurchaseComplete';
import { upgradesMessages } from './upgrades';
import { valueMessages } from './values';

const messages = {
    [LOCALES.ENGLISH]: {
        // Landing Page

        ...commonMessages[LOCALES.ENGLISH],
        ...heroMessages[LOCALES.ENGLISH],
        ...heroVariationAMessages[LOCALES.ENGLISH],
        ...simplePrintMessages[LOCALES.ENGLISH],
        ...amountPrintMessages[LOCALES.ENGLISH],
        ...valueMessages[LOCALES.ENGLISH],
        ...upgradesMessages[LOCALES.ENGLISH],
        ...stepsMessages[LOCALES.ENGLISH],
        ...enviromentMessages[LOCALES.ENGLISH],
        ...faqSectionMessages[LOCALES.ENGLISH],
        ...disclaimersMessages[LOCALES.ENGLISH],
        ...footerMessages[LOCALES.ENGLISH],

        // Printer selection

        ...chooseYourPrinterMessages[LOCALES.ENGLISH],
        ...comparisonTableMessages[LOCALES.ENGLISH],
        ...howManyPagesMessages[LOCALES.ENGLISH],
        ...paperQuantityMessages[LOCALES.ENGLISH],
        ...orderYourPlanMessages[LOCALES.ENGLISH],
        ...summaryMessages[LOCALES.ENGLISH],

        // Automatic Renewal notice

        ...renewalNoticeMessages[LOCALES.ENGLISH],

        // Checkout

        ...checkoutMessages[LOCALES.ENGLISH],
        ...purchaseCompleteMessages[LOCALES.ENGLISH],

        // Terms of Service

        ...termsOfServiceMessages[LOCALES.ENGLISH],

        // PrinterUpgrade

        ...upgradeLandingMessages[LOCALES.ENGLISH],
        ...upgradePrinterSelectionMessages[LOCALES.ENGLISH],
        ...upgradePurchaseCompleteMessages[LOCALES.ENGLISH],

        // Sunset

        ...sunsetMessages[LOCALES.ENGLISH],

        // Invalid campaign store

        ...notFoundMessages[LOCALES.ENGLISH],
    },
    [LOCALES.SPANISH]: {
        // Landing Page

        ...commonMessages[LOCALES.SPANISH],
        ...heroMessages[LOCALES.SPANISH],
        ...heroVariationAMessages[LOCALES.SPANISH],
        ...simplePrintMessages[LOCALES.SPANISH],
        ...amountPrintMessages[LOCALES.SPANISH],
        ...valueMessages[LOCALES.SPANISH],
        ...upgradesMessages[LOCALES.SPANISH],
        ...stepsMessages[LOCALES.SPANISH],
        ...enviromentMessages[LOCALES.SPANISH],
        ...faqSectionMessages[LOCALES.SPANISH],
        ...disclaimersMessages[LOCALES.SPANISH],
        ...footerMessages[LOCALES.SPANISH],

        // Printer selection

        ...chooseYourPrinterMessages[LOCALES.SPANISH],
        ...comparisonTableMessages[LOCALES.SPANISH],
        ...howManyPagesMessages[LOCALES.SPANISH],
        ...paperQuantityMessages[LOCALES.SPANISH],
        ...orderYourPlanMessages[LOCALES.SPANISH],
        ...summaryMessages[LOCALES.SPANISH],

        // Renewal notice

        ...renewalNoticeMessages[LOCALES.SPANISH],

        // Checkout

        ...checkoutMessages[LOCALES.SPANISH],
        ...purchaseCompleteMessages[LOCALES.SPANISH],

        // Terms of Service

        ...termsOfServiceMessages[LOCALES.SPANISH],

        // PrinterUpgrade

        ...upgradeLandingMessages[LOCALES.SPANISH],
        ...upgradePrinterSelectionMessages[LOCALES.SPANISH],
        ...upgradePurchaseCompleteMessages[LOCALES.SPANISH],

        // Sunset

        ...sunsetMessages[LOCALES.SPANISH],

        // Invalid campaign store

        ...notFoundMessages[LOCALES.SPANISH],
    },
};

export default messages;
