import { FooterMessages } from 'lang/messages/homeFooterBar';

export const LINKS: (isSpanish: boolean) => {
    id: number;
    link: string;
    textId: keyof typeof FooterMessages;
}[] = (isSpanish: boolean) => {
    return [
        {
            id: 0,
            link: isSpanish
                ? 'https://www.hp.com/es-es/privacy/use-of-cookies.html'
                : 'https://www.hp.com/us-en/privacy/use-of-cookies.html',
            textId: 'COOKIES',
        },
        {
            id: 1,
            link: isSpanish
                ? 'https://www.hp.com/es-es/privacy/privacy-central.html'
                : 'https://www.hp.com/us-en/privacy/your-privacy-choices.html',
            textId: 'CHOICES',
        },
        {
            id: 2,
            link: isSpanish
                ? 'https://www.hp.com/es-es/privacy/limited_warranty.html'
                : 'https://www.hp.com/us-en/privacy/privacy.html',
            textId: 'PRIVACY',
        },
        {
            id: 3,
            link: isSpanish
                ? 'https://www.hp.com/es-es/terms-of-use.html'
                : 'https://www.hp.com/us-en/terms-of-use.html',
            textId: 'TOS',
        },
    ];
};
