import { AvailablePrinters } from './constants';

export const buildDefaultSelection = (printerConfig?: {
    toDisable: AvailablePrinters;
    previousSelection: string[];
}) => {
    return {
        printer: undefined,
        inkPlan: undefined,
        paper: undefined,
        disabledPrinterIds: [
            ...(printerConfig?.previousSelection || []),
            printerConfig?.toDisable || '',
        ],
        isInkPlanSelected: false,
        isPrinterSelected: false,
    };
};
