import { useLocation } from 'react-router-dom-v5-compat';
import { checkForSunset } from 'components/RoutedContent/utils';
import Sunset from 'screens/Sunset/Sunset';

function CheckForSunset({ children }: { children: JSX.Element }) {
    const location = useLocation();

    if (checkForSunset(true, location.pathname)) {
        return <Sunset />;
    }

    return children;
}
export default CheckForSunset;
